import { Checkbox, Col, Typography } from 'antd'
import { CheckboxValueType } from 'antd/es/checkbox/Group'

const { Link } = Typography
const CheckboxGroup = Checkbox.Group

export interface ConfirmFormProps {
  checkedList: string[]
  setCheckedList: (list: string[]) => void
}

const ConfirmForm: React.FC<ConfirmFormProps> = ({ checkedList, setCheckedList }) => {
  const options = [
    { label: '32-amp Liteon chargers are being used', value: '1' },
    { label: 'SWTCH’s network package is installed (no physical CT clamps)', value: '2' },
    { label: 'No other equipment is used.', value: '3' },
    { label: 'Chargers remain powered on post-installation.', value: '4' },
  ]

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list as string[])
  }

  return (
    <Col>
      <p className="heading-03-bold">{'Installation Eligibility Check'}</p>
      <p className="heading-06-regular">
        This application currently supports installations that meet the following criteria. If your installation does
        not meet these criteria, then fill out this
        <Link target="_blank" href="https://airtable.com/app0wvmB6Pz18Y5xT/shrRcpswGzHopEdjK">
          {' commissioning checklist '}
        </Link>
        instead.
      </p>
      <CheckboxGroup options={options} value={checkedList} onChange={onChange} />
      <p className="heading-06-regular">
        {
          'In order for the SWTCH team to set up the site correctly, we require accurate information and that the site has passed ESA inspection and for critical installation information to be shared with us. Please ensure that the information provided throughout this process is complete and accurate. This information is critical to ensure operational reliability and prevent damage to the equipment or building. By continuing, you agree that this installation meets all of the criteria above.'
        }
      </p>
    </Col>
  )
}

export default ConfirmForm
